import React, { useEffect, useState } from "react";
import "./Nofication.css";
import axios from "axios";


const getStatusClass = (status) => {
  switch (status) {
    case "Ongoing":
      return "status-ongoing";
    case "Pending":
      return "status-pending";
    case "Success":
      return "status-success";
    default:
      return "";
  }
};

// Main component
const Nofication = () => {
  const [notlist, setNotList] = useState([]); // Ensure notlist is initialized as an empty array
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [filteredNotList, setFilteredNotList] = useState([]); // Filtered list based on search

  // Fetch notifications on component mount
  useEffect(() => {
    if (searchTerm) {
      Notificationlist(searchTerm); // Fetch notifications if searchTerm is provided
    }
  }, [searchTerm]); // Triggered when searchTerm changes

  // Function to fetch the notification list
  const Notificationlist = async (searchTerm) => {
    if (!searchTerm) {
      setNotList([]); // Clear list if no search term
      setFilteredNotList([]); // Clear filtered list
      return;
    }

    const params = {
      user_id: searchTerm, // Use searchTerm to filter notifications
    };

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5003/studyabroad/service/list",
        params
      );

      if (response.status === 200 && response.data.result) {
        const fetchedData = response.data.data || []; // Ensure data is an array
        setNotList(fetchedData); // Save the notification list
        setFilteredNotList(fetchedData); // Set the filtered list initially to all notifications
        console.log(fetchedData, "Fetched notifications");
      } else {
        console.warn("No notifications found:", response.data.message);
        setNotList([]);
        setFilteredNotList([]);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setNotList([]);
      setFilteredNotList([]);
    }
  };

  // Update search input and fetch data based on search term
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  return (
    <div className="container cont">
      <h1>Notification</h1>

      {/* Search Bar */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by User ID..."
          value={searchTerm}
          onChange={handleSearch} // Update search term on input change
          className="search-bar"
        />
      </div>

      {/* List of Notifications */}
      <div className="people-list">
        {searchTerm === ""  ? (
          <p>Please enter a User ID to search for notifications.</p>
        ) : filteredNotList.length > 0 ? (
          filteredNotList.map((person) => (
            <div key={person.user_id} className="person-card">
              <div className="person-header">
                <h3>{person.user_name}</h3>
                <span className="user-id">ID: {person.u_id}</span>
              </div>
              <div className="person-services">
                <strong>Service: {person.s_name}</strong>
              </div>
              <div className="person-description">
                <p>{person.s_message}</p>
              </div>
              <div
                className={`status-badge ${getStatusClass(person.s_status)}`}
              >
                {person.s_status}
              </div>
            </div>
          ))
        ) : (
          <p>No results found for the given User ID.</p>
        )}
      </div>
    </div>
  );
};

export default Nofication;
